const technologiesData = [
  {
    id: 1,
    title: "Python",
    text: "Intermediate knowledge of Python and common libraries."
  },
  {
    id: 2,
    title: "Django / Django Rest",
    text: "Intermediate knowledge of Django building sites and APIs."
  },
  {
    id: 3,
    title: "Flask",
    text: "Intermediate knowledge of Flask building sites."
  },
  {
    id: 4,
    title: "React",
    text: "Beginner knowledge of React building sites."
  },
  {
    id: 5,
    title: "Java",
    text: "Intermediate knowledge of Java creating Algorithms and Data structures."
  },
  {
    id: 6,
    title: "NLP",
    text: "Beginner knowledge of natural language processing using spaCy and SciKitLearn."
  },
  {
    id: 7,
    title: "TypeScript",
    text: "Beginner knowledge of Typescript and its benefits compared to Js."
  },
  {
    id: 8,
    title: "MicroServices",
    text: "Intermediate knowledge of Micro service architecture's."
  },
  {
    id: 9,
    title: "Go",
    text: "beginner knowledge of Go, primarily using Go to create Terraform providers."
  },
  {
    id: 10,
    title: "Terraform",
    text: "beginner knowledge of Terraform."
  },
]

export default technologiesData