const experienceData = [
  {
    id: 1,
    title: "cloudsmith",
    position: "Associate Software Engineer",
    text: "I started in cloudsmith right out of university as a graduate engineer, during that time I was primarily working on our main platform, using Python, Django and DRF to add to our existing API functionality. I have also worked in the frontend of the app, which has been migrating from Jinja templating to React with TS. Along with this I was able to gain experience using AWS services such as ECS, Lambda, IAM, Aurora. I have also had opportunities to work outside of the main platform with our opensource projects, I was able to pick up Go to work on our Terraform provider along with Click for our python CLI."
  },
  {
    id: 2,
    title: "PwC",
    position: "Intern Software Engineer",
    text: "Year long placement with PwC Belfast. Gaining experience in Python using Django, Django-rest, Flask and data libraries. JavaScript creating webcomponents using Stencil.js, Microservices with Express.js and React."
  },
  {
    id: 3,
    title: "Exploristics",
    position: "Junior Software Engineer",
    text: "Summer Internship, working primarily with AWS CDK in Python. Creating a custom CloudWatch dashboard for tracking costs, usage and errors on the platform."
  },
  {
    id: 4,
    title: "Kainos",
    position: "A.I Camp",
    text: "Two week course on the fundamentals of Artificial Intelligence. Followed by and 11 hour Hackathon where we created an NLP app to find bias in News reporting. Code on my github."
  },
  {
    id: 5,
    title: "PwC",
    position: "Tech Academy",
    text: "Week long placement working around the different technology departments within PwC."
  },
  {
    id: 6,
    title: "Kainos",
    position: "Code Camp",
    text: "Two week long coding bootcamp, learning the basics of Android app development."
  },
  {
    id: 7,
    title: "Liberty IT",
    position: "Work Experience",
    text: "A-Level work experience week, working on basic web development and learning about Alexa skills."
  }

]

export default experienceData